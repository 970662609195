<template>
  <CreateUpdateTemplate
    :customClass="'proposal-update'"
    v-if="getPermission('proposal:update')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Update {{ proposalSubject }}</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        Save Proposal
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="proposalForm"
        v-model="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container fluid>
          <!-- <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 70vh; position: relative"
          > -->
          <v-row>
            <v-col md="6">
              <v-container fluid>
                <table class="width-100">
                  <tr>
                    <td class="font-size-16 pb-2" width="200">
                      Proposal Subject
                    </td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Subject"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-text-field
                        v-model="proposalCreate.subject"
                        dense
                        filled
                        label="Subject"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                        :rules="[
                          validateRules.required(
                            proposalCreate.subject,
                            'Proposal Subject'
                          ),
                          validateRules.minLength(
                            proposalCreate.subject,
                            'Proposal Subject',
                            1
                          ),
                          validateRules.maxLength(
                            proposalCreate.subject,
                            'Proposal Subject',
                            100
                          ),
                        ]"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">Customer Name</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Name"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model="proposalCreate.name"
                        label="Name"
                        :rules="[
                          validateRules.required(proposalCreate.name, 'Name'),
                          validateRules.minLength(
                            proposalCreate.name,
                            'Name',
                            1
                          ),
                          validateRules.maxLength(
                            proposalCreate.name,
                            'Name',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">
                      Customer Company
                    </td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Company"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-text-field
                        v-model="proposalCreate.company"
                        dense
                        filled
                        label="Company"
                        :rules="[
                          validateRules.minLength(
                            proposalCreate.company,
                            'Company',
                            1
                          ),
                          validateRules.maxLength(
                            proposalCreate.company,
                            'Company',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">
                      Customer Email
                    </td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Email"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-text-field
                        v-model="proposalCreate.email"
                        dense
                        filled
                        label="Email"
                        :rules="[
                          validateRules.validEmail(
                            proposalCreate.email,
                            'Email'
                          ),
                          validateRules.minLength(
                            proposalCreate.email,
                            'Email',
                            1
                          ),
                          validateRules.maxLength(
                            proposalCreate.email,
                            'Email',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">
                      Customer Phone No.
                    </td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Phone Number"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-text-field
                        v-model="proposalCreate.phone_number"
                        dense
                        filled
                        label="Phone Number"
                        v-mask="currentPhoneMask"
                        :rules="[
                          validateRules.required(
                            proposalCreate.phone_number,
                            'Phone Number'
                          ),
                          validateRules.minLength(
                            proposalCreate.phone_number,
                            'Phone Number',
                            2
                          ),
                          validateRules.maxLength(
                            proposalCreate.phone_number,
                            'Phone Number',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">
                      Customer Website
                    </td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Website"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-text-field
                        v-model="proposalCreate.website"
                        dense
                        filled
                        label="Website"
                        :rules="[
                          validateRules.minLength(
                            proposalCreate.website,
                            'Website',
                            1
                          ),
                          validateRules.maxLength(
                            proposalCreate.website,
                            'Website',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">Proposal Date</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Proposal Date"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <DatePicker
                        solo
                        :pageLoading.sync="pageLoading"
                        :placeholder="'Proposal Date'"
                        v-model="proposalCreate.proposal_date"
                      ></DatePicker>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">Open Till</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Open Till"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <DatePicker
                        solo
                        clearable
                        :pageLoading.sync="pageLoading"
                        :placeholder="'Open Till'"
                        v-model="proposalCreate.open_till"
                      ></DatePicker>
                    </td>
                  </tr>
                </table>
              </v-container>
            </v-col>
            <v-col md="6">
              <v-container fluid>
                <table class="width-100">
                  <tr>
                    <td class="font-size-16 pb-2" width="200">
                      Proposal number
                    </td>
                    <td
                      colspan="2"
                      class="font-weight-700 font-size-16 pb-4"
                      width="150"
                    >
                      <v-skeleton-loader
                        v-if="pageLoading"
                        class="custom-skeleton"
                        type="text"
                      ></v-skeleton-loader>
                      <template v-else>
                        <v-text-field
                          dense
                          color="cyan"
                          filled
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          readonly
                          v-model="proposalCreate.barcode"
                          label="Proposal number"
                          solo
                          flat
                          hide-details
                          class="remove-border cursor-default"
                        ></v-text-field>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">Reference #</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Reference #"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model="proposalCreate.reference"
                        label="Reference #"
                        :rules="[
                          validateRules.minLength(
                            proposalCreate.reference,
                            'Reference',
                            1
                          ),
                          validateRules.maxLength(
                            proposalCreate.reference,
                            'Reference',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">Address</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Address"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-text-field
                        v-model="proposalCreate.address"
                        dense
                        filled
                        label="Address"
                        solo
                        flat
                        :rules="[
                          validateRules.minLength(
                            proposalCreate.address,
                            'Address',
                            1
                          ),
                          validateRules.maxLength(
                            proposalCreate.address,
                            'Address',
                            100
                          ),
                        ]"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">Country</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Country"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-text-field
                        v-model="proposalCreate.country"
                        dense
                        filled
                        label="Country"
                        :rules="[
                          validateRules.minLength(
                            proposalCreate.country,
                            'Country',
                            1
                          ),
                          validateRules.maxLength(
                            proposalCreate.country,
                            'Country',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">State</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="State"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-text-field
                        v-model="proposalCreate.state"
                        dense
                        filled
                        label="State"
                        :rules="[
                          validateRules.minLength(
                            proposalCreate.state,
                            'State',
                            1
                          ),
                          validateRules.maxLength(
                            proposalCreate.state,
                            'State',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">City</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="City"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-text-field
                        v-model="proposalCreate.city"
                        dense
                        filled
                        label="City"
                        :rules="[
                          validateRules.minLength(
                            proposalCreate.city,
                            'City',
                            1
                          ),
                          validateRules.maxLength(
                            proposalCreate.city,
                            'City',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">Postal Code</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Postal Code"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-text-field
                        v-model="proposalCreate.zip"
                        dense
                        filled
                        label="Postal Code"
                        v-mask="'############'"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">Assigned</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Assigned"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        :items="userList"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model="proposalCreate.assigned_user"
                        label="Assigned"
                        solo
                        flat
                        item-color="cyan"
                        item-text="full_name"
                        item-value="id"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No User Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                </table>
              </v-container>
            </v-col>
            <v-col cols="12" v-if="getPermission('line-item:update')">
              <v-container fluid>
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Line Items
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <v-row dense>
                      <v-col cols="12" class="pt-0 pb-0">
                        <ProposalLineItem
                          :pageLoading.sync="pageLoading"
                          :updateData.sync="updateLineItem"
                        ></ProposalLineItem>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <ProposalLineItemCalculation
                          :pageLoading.sync="pageLoading"
                          :updateData.sync="updateLineItemCalculation"
                        ></ProposalLineItemCalculation>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col cols="12">
              <ProposalNotesAttachment
                :pageLoading.sync="pageLoading"
                :updateData.sync="updateNotesAttachment"
                isProposal
              ></ProposalNotesAttachment>
            </v-col>
          </v-row>
          <!-- </perfect-scrollbar> -->
        </v-container>
      </v-form>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CLEAR_ERROR, PUT, GET } from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import ProposalLineItem from "@/view/pages/partials/Line-Item.vue";
import ProposalLineItemCalculation from "@/view/pages/partials/Line-Item-Calculation.vue";
import ProposalNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import LineItemMixin from "@/core/lib/line-item/line.item.mixin";
import {
  LineItemEventBus,
  LineItemCalculationEventBus,
} from "@/core/lib/line-item/line.item.lib";

export default {
  mixins: [CommonMixin, ValidationMixin, LineItemMixin],
  name: "proposal-update",
  title: "Update Proposal",
  data() {
    return {
      pageLoading: false,
      barcodeDialog: false,
      ProposalUpdated: false,
      LineItemCreated: false,
      proposal: 0,
      barcodeSetting: new Object(),
      updateLineItem: new Array(),
      updateLineItemCalculation: new Object(),
      updateNotesAttachment: new Object(),
      lineItem: new Array(),
      lineItemCalculation: new Object(),
      notesAttachment: new Object(),
      userList: new Array(),
      proposalCreate: new Object({
        reference: null,
        assigned_user: null,
        name: null,
        company: null,
        email: null,
        website: null,
        phone_number: null,
        country: null,
        zip: null,
        city: null,
        state: null,
        address: null,
        subject: null,
        status: null,
        is_public: null,
        proposal_date: null,
        open_till: null,
        adjustment: null,
        attachments: [],
        admin_remark: null,
        client_remark: null,
        additional_remarks: null,
      }),
    };
  },
  components: {
    DatePicker,
    ProposalLineItem,
    ProposalLineItemCalculation,
    ProposalNotesAttachment,
    CreateUpdateTemplate,
  },
  methods: {
    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "proposals/options",
        })
        .then(({ data }) => {
          _this.barcodeSetting = data.barcode_setting;
          _this.proposalCreate.barcode = data.barcode;
          _this.userList = data.users;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    async updateOrCreate() {
      const _this = this;
      if (!_this.$refs.proposalForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        reference: _this.proposalCreate.reference || null,
        assigned_user: _this.proposalCreate.assigned_user || null,
        name: _this.proposalCreate.name || null,
        company: _this.proposalCreate.company || null,
        email: _this.proposalCreate.email || null,
        website: _this.proposalCreate.website || null,
        phone_number: _this.proposalCreate.phone_number || null,
        country: _this.proposalCreate.country || null,
        zip: _this.proposalCreate.zip || null,
        city: _this.proposalCreate.city || null,
        state: _this.proposalCreate.state || null,
        address: _this.proposalCreate.address || null,
        subject: _this.proposalCreate.subject || null,
        status: _this.proposalCreate.status || null,
        is_public: _this.proposalCreate.is_public || null,
        proposal_date: _this.proposalCreate.proposal_date || null,
        open_till: _this.proposalCreate.open_till || null,
        adjustment:
          _this.lodash.isEmpty(_this.lineItemCalculation) === false
            ? _this.lineItemCalculation.adjustmentAmount
            : null,
        admin_remark:
          _this.lodash.isEmpty(_this.notesAttachment) === false
            ? _this.notesAttachment.admin_notes
            : null,
        client_remark:
          _this.lodash.isEmpty(_this.notesAttachment) === false
            ? _this.notesAttachment.client_notes
            : null,
        attachments:
          _this.lodash.isEmpty(_this.notesAttachment) === false
            ? _this.notesAttachment.documents
            : [],
        additional_remarks:
          _this.lodash.isEmpty(_this.lineItemCalculation) === false
            ? _this.lineItemCalculation.description
            : null,
      });

      if (!_this.ProposalUpdated) {
        try {
          let { data } = await _this.$store.dispatch(PUT, {
            url: "proposals/" + _this.proposal,
            data: formData,
          });
          _this.proposal = data.id;
          _this.ProposalUpdated = true;
        } catch (error) {
          _this.proposal = 0;
          _this.ProposalUpdated = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Proposal is not updated. Please try again.")
          );
        }
      }

      if (!_this.LineItemCreated && _this.ProposalUpdated) {
        try {
          await _this.CreateLineItems({
            type: "proposal",
            parent: _this.proposal,
            formData: _this.lineItem,
          });
          _this.LineItemCreated = true;
        } catch (error) {
          _this.LineItemCreated = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Line items are not updated. Please try again.")
          );
        }
      }

      _this.formLoading = false;

      if (_this.ProposalUpdated && _this.LineItemCreated) {
        _this.$router.push(
          _this.getDefaultRoute("proposal.detail", {
            params: {
              id: _this.proposal,
            },
          })
        );
      }
    },
    getProposal() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "proposals/" + _this.proposal,
        })
        .then(({ data }) => {
          _this.proposalCreate = new Object({
            barcode: data.barcode ? data.barcode : null,
            reference: data.reference ? data.reference : null,
            assigned_user: data.assigned_user ? data.assigned_user.id : null,
            name: data.name ? data.name : null,
            company: data.company ? data.company : null,
            email: data.email ? data.email : null,
            website: data.website ? data.website : null,
            phone_number: data.phone_number ? data.phone_number : null,
            country: data.country ? data.country : null,
            zip: data.zip ? data.zip : null,
            city: data.city ? data.city : null,
            state: data.state ? data.state : null,
            address: data.address ? data.address : null,
            subject: data.subject ? data.subject : null,
            status: data.status ? data.status : null,
            assigned: data.assigned ? data.assigned : null,
            is_public: data.is_public ? data.is_public : null,
            admin_remark: data.admin_remark ? data.admin_remark : null,
            client_remark: data.client_remark ? data.client_remark : null,
            additional_remarks: data.additional_remarks
              ? data.additional_remarks
              : null,
            adjustment: data.adjustment ? data.adjustment : null,
            proposal_date: data.date ? data.date : null,
            open_till: data.open_till ? data.open_till : null,
            attachments: [],
          });

          let lineItems = new Array();
          if (data.line_items && data.line_items.length > 0) {
            for (let i = 0; i < data.line_items.length; i++) {
              lineItems.push({
                original_product: data.line_items[i].original_product,
                id: data.line_items[i].id,
                description: data.line_items[i].description,
                quantity: data.line_items[i].quantity,
                tax: data.line_items[i].tax,
                rate: data.line_items[i].rate,
                total: data.line_items[i].total,
                warranty_data: null,
                warranty_start_date: null,
                warranty_unique_id: null,
                warranty_description: null,
                minimum_rental: null,
                maximum_rental: null,
              });
            }
          }

          _this.updateLineItem = lineItems;

          _this.updateLineItemCalculation = new Object({
            additional_remarks: data.additional_remarks,
            sub_total: data.sub_total,
            tax_amount: data.tax_amount,
            discount_value: data.discount_value,
            discount_type: data.discount_type,
            discount_value_type: data.discount_value_type,
            adjustment: data.adjustment,
            total: data.total,
          });

          _this.updateNotesAttachment = new Object({
            admin_remark: data.admin_remark,
            client_remark: data.client_remark,
            notify_admin: 0,
            notify_customer: 0,
            notify_engineer: 0,
            documents: data.attachments,
          });
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  beforeDestroy() {
    /*LineItemEventBus.$off("update:line-item");
    LineItemCalculationEventBus.$off("update:line-item-calculation");
    NoteAttachmentEventBus.$off("update:notes-attachment");*/
  },
  mounted() {
    const _this = this;
    _this.getOptions();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Proposal", route: "proposal" },
      { title: "Update" },
    ]);

    _this.proposal = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.proposal <= 0) {
      _this.goBack();
    }

    _this.getProposal();

    LineItemEventBus.$on("update:line-item", (argument) => {
      _this.lineItem = argument;
    });

    LineItemCalculationEventBus.$on(
      "update:line-item-calculation",
      (argument) => {
        _this.lineItemCalculation = argument;
      }
    );

    NoteAttachmentEventBus.$on("update:notes-attachment", (argument) => {
      _this.notesAttachment = argument;
    });
  },
  computed: {
    proposalSubject() {
      return this.proposalCreate.subject;
    },
  },
};
</script>
